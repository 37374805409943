@import '../../input/style/mixin';

@transfer-prefix-cls: ~'@{ant-prefix}-transfer';

.status-color(@color) {
  .@{transfer-prefix-cls}-list {
    border-color: @color;

    &-search:not([disabled]) {
      border-color: @input-border-color;

      &:hover {
        .hover();
      }

      &:focus {
        .active();
      }
    }
  }
}

.@{transfer-prefix-cls} {
  &-status-error {
    .status-color(@error-color);
  }

  &-status-warning {
    .status-color(@warning-color);
  }
}
