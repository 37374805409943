@import '../../input/style/mixin';

@select-prefix-cls: ~'@{ant-prefix}-select';

.select-status-color(
  @text-color;
  @border-color;
  @background-color;
  @hoverBorderColor;
  @outlineColor;
) {
  &.@{select-prefix-cls}:not(.@{select-prefix-cls}-disabled):not(.@{select-prefix-cls}-customize-input) {
    .@{select-prefix-cls}-selector {
      background-color: @background-color;
      border-color: @border-color !important;
    }
    &.@{select-prefix-cls}-open .@{select-prefix-cls}-selector,
    &.@{select-prefix-cls}-focused .@{select-prefix-cls}-selector {
      .active(@border-color, @hoverBorderColor, @outlineColor);
    }
  }

  .@{select-prefix-cls}-feedback-icon {
    color: @text-color;
  }
}

.select-status-base(@prefix-cls) {
  .@{prefix-cls} {
    &-status-error {
      .select-status-color(@error-color, @error-color, @select-background, @error-color-hover, @error-color-outline);
    }

    &-status-warning {
      .select-status-color(@warning-color, @warning-color, @input-bg, @warning-color-hover, @warning-color-outline);
    }

    &-status-success {
      .@{prefix-cls}-feedback-icon {
        color: @success-color;
      }
    }

    &-status-validating {
      .@{prefix-cls}-feedback-icon {
        color: @primary-color;
      }
    }

    &-status-error,
    &-status-warning,
    &-status-success,
    &-status-validating {
      &.@{prefix-cls}-has-feedback {
        //.@{prefix-cls}-arrow,
        .@{prefix-cls}-clear {
          right: 32px;
        }

        .@{prefix-cls}-selection-selected-value {
          padding-right: 42px;
        }
      }
    }

    &-feedback-icon {
      font-size: @font-size-base;
      text-align: center;
      visibility: visible;
      animation: zoomIn 0.3s @ease-out-back;
      pointer-events: none;

      &:not(:first-child) {
        margin-left: 8px;
      }
    }
  }
}

.select-status-base(@select-prefix-cls);
